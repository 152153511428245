// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
        $attention: (0px, 0px, 0px, 0px, 35px, 35px, 62px, 55px, '/skin/images/sprite.png', 'attention', );
        $check: (35px, 0px, -35px, 0px, 27px, 21px, 62px, 55px, '/skin/images/sprite.png', 'check', );
        $checkbox: (35px, 21px, -35px, -21px, 17px, 14px, 62px, 55px, '/skin/images/sprite.png', 'checkbox', );
        $close: (20px, 35px, -20px, -35px, 14px, 14px, 62px, 55px, '/skin/images/sprite.png', 'close', );
        $not: (0px, 35px, 0px, -35px, 20px, 20px, 62px, 55px, '/skin/images/sprite.png', 'not', );
    $spritesheet-sprites: ($attention, $check, $checkbox, $close, $not, );
    $spritesheet: (62px, 55px, '/skin/images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
