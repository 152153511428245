.main-section {
  padding: 50px 0;
  p:last-child,
  .ttl-base:last-child {
    margin-bottom: 0;
  }
  &_primary {
    background-color: $primary-color;
    color: $light-color;
    a {
      color: $light-color;
    }
  }
  &_bg {
    background-color: $bg-color;
  }
  &_base {
    background-color: $base-color;
    color: $light-color;
    a {
      color: $light-color;
    }
  }
  &_b-t {
    border-top: 8px solid $secondary-color;
  }
  &_b-b {
    border-bottom: 8px solid $secondary-color;
  }
  @include laptop {
    padding: 30px 0;
  }
}

.price-tbl {
  background-color: transparent;
  tr {
    border-bottom: 1px solid $base-color-lightest;
    &:last-child:not(:first-child) {
      border-bottom: none;
    }
  }
  th {
    background-color: transparent;
    color: $base-color;
    font-size: 18px;
    font-weight: 500;
    padding: 0 0 20px;
  }
  td {
    &:last-child {
      text-align: right;
      padding-right: 0;
      white-space: nowrap;
    }
    &:first-child {
      padding-left: 0;
    }
  }
  .main-section_bg & {
    tr {
      border-color: $light-color;
    }
  }
  .img-tbl-block & {
    tr {
      &:last-child:not(:first-child) {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
}

.table-container {
  overflow: auto;
  //-webkit-overflow-scrolling: touch;
  margin: 1.6em 0;
  @include tablets {
    margin: 1.2em 0;
  }
  table {
    margin: 0;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:horizontal {
    background-color: $base-color-lightest;
    border-radius: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid $base-color-lightest;
    background-color: $base-color-light;
    border-radius: 5px;
  }
}

.img-block {
  display: flex;
  &__img {
    flex: none;
    margin-right: 5%;
    max-width: 40%;
    &-inner {
      position: relative;
      &:before {
        background-color: $base-color;
        content: '';
        height: 80%;
        position: absolute;
        top: 0;
        right: -10px;
        width: 100%;
      }
    }
    img {
      position: relative;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &-text {
      flex: 1;
    }
    &-btn {
      margin-top: 1em;
    }
  }
  @include tablets {
    display: block;
    &__img {
      float: left;
      margin-bottom: 15px;
    }
    &__content {
      display: block;
      &-btn {
        text-align: center;
      }
    }
  }
  @include phones {
    &__img {
      float: none;
      max-width: 100%;
    }
  }
}

.img-tbl-block {
  display: flex;
  &__table {
    flex: none;
    margin-right: 8%;
    max-width: 480px;
    width: 60%;
  }
  &__images {
    display: flex;
    flex-direction: column;
    flex: 1;
    &-item {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      flex: 1;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include tablets {
    &__table {
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
    &__images {
      display: none;
    }
  }
}

.img-ttl-block {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  &__img {
    border-right: 8px solid $secondary-color;
    flex: none;
    margin-right: 3%;
    max-width: 40%;
  }
  &__content {
    font-size: 46px;
    text-transform: uppercase;
    &-ttl {
      margin-bottom: .4em;
    }
    &-text {
      color: $secondary-color;
      font-size: 1.2em;
      font-weight: 500;
    }
  }
  @include desktop {
    &__content {
      font-size: 36px;
    }
  }
  @include tablets {
    margin-bottom: 20px;
    &__content {
      font-size: 18px;
    }
  }
  @include phones {
    flex-direction: column-reverse;
    &__img {
      border-right: none;
      margin-right: 0;
      margin-top: 15px;
      max-width: 100%;
    }
  }
}

.contacts-map {
  height: 50vh;
}