.svg-icon {
  display: inline-block;
  height: 1em;
  fill: currentColor;
}

.icon {
  &:before {
    display: inline-block;
    content: '';
  }
}

.icon-checkbox {
  &:before {
    @include sprite($checkbox);
  }
}