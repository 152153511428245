.icon-arrow {
  font-size:(18/(10*1.7))*1rem;
  width:(11/18)*1em;
}
.icon-phone {
  font-size:(23/(10*1.7))*1rem;
  width:(23/23)*1em;
}
.icon-point {
  font-size:(30/(10*1.7))*1rem;
  width:(30/30)*1em;
}
.icon-search {
  font-size:(30/(10*1.7))*1rem;
  width:(30/30)*1em;
}
