.work-steps {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  &__item {
    flex: none;
    margin-bottom: 30px;
    width: (100%/3);
  }
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 30px;
  }
  &__img {
    text-align: center;
  }
  &__num {
    background-color: $primary-color;
    color: $light-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    min-width: 54px;
    padding: 0 10px;
    position: absolute;
    top: -19px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  &__text {
    background-color: $light-color;
    flex: 1;
    font-size: 14px;
    line-height: 1.3;
    margin: 0 auto;
    max-width: 340px;
    min-height: 114px;
    padding: 30px 20px 12px;
    position: relative;
	width: 100%;
  }
  @include tablets {
    margin-left: -15px;
    &__item {
      margin-bottom: 15px;
      width: 50%;
    }
    &__inner {
      margin-left: 15px;
    }
  }
  @include phones {
    &__item {
      width: 100%;
    }
  }
}