.main-footer {
  background-color: $base-color;
  color: $light-color;
  &__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 0;
  }
  &__contacts {
    text-align: right;
    &-address {
      font-size: 15px;
      margin-bottom: 10px;
    }
    &-phones {
      font-size: 18px;
      font-weight: 500;
      a {
        color: $light-color;
        text-decoration: none;
      }
    }
  }
  @include tablets {
    &__layout {
      flex-direction: column-reverse;
      padding: 20px 0;
    }
    &__contacts {
      border-bottom: 1px solid rgba($light-color, .2);
      margin-bottom: 15px;
      padding-bottom: 15px;
      text-align: center;
      &-phones {
        font-size: 16px;
      }
    }
  }
}

.copy {
  font-size: 14px;
  &__rights {
    margin-bottom: 10px;
  }
  @include tablets {
    text-align: center;
  }
}

.footer-menu {
  background-color: $primary-color;
  color: $light-color;
  padding: 30px 0;
  &__list {
    display: flex;
    font-size: 14px;
    margin-left: -20px;
  }
  &__item {
    flex: 1;
    margin-left: 20px;
  }
  &__ttl {
    font-weight: bold;
    text-transform: uppercase;
    &-name {
      color: $light-color;
      text-decoration: none;
    }
  }
  &__sub {
    margin-top: 15px;
    &-item {
      margin: 7px 0;
    }
    &-link {
      color: $light-color;
      opacity: .7;
      text-decoration: none;
      &:hover {
        opacity: 1;
      }
    }
  }
  @include tablets {
    padding: 15px 0;
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      flex: none;
      margin: 5px 0;
      text-align: center;
      width: 50%;
    }
    &__sub {
      display: none;
    }
  }
  @include phones {
    &__item {
      width: 100%;
    }
  }
}