.inp-base, .textarea-base, .select-base {
  @extend %form-element;
}

.textarea-base {
  height: $textarea-height;
  resize: none;
  padding: 12px;
  @include phones {
    height: 100px;
  }
}

.switch {
  cursor: pointer;
  &__inp {
    display: none;
    &:checked + .switch__el:before {
      opacity: 1;
    }
  }
  &__el {
    background-color: $input-bg-color;
    border: 2px solid $input-border-color;
    display: inline-block;
    height: $input-checkbox-size;
    margin-right: 5px;
    position: relative;
    vertical-align: middle;
    width: $input-checkbox-size;
    &:before {
      transition: all .3s ease;
      background-color: $primary-color;
      border-radius: 50%;
      content: '';
      opacity: 0;
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
    }
    &_radio {
      border-radius: 50%;
    }
    &_checkbox {
      border-radius: 5px;
    }
  }
}

.form-base {
  &__field {
    margin-bottom: 15px;
  }
  &__btns {
    text-align: center;
    .btn {
      min-width: 220px;
    }
  }
  .mycms-dialog & {
    margin: 0 auto;
    max-width: 440px;
  }
  @include phones {
    &__btns {
      .btn {
        width: 100%;
      }
    }
  }
}