.works-slider {
  $root: &;

  &:hover {
    .slick-next, .slick-prev {
      opacity: 1;
    }
  }

  &__slidee {
    display: flex;
  }
  &__item {
    background-color: $base-color;
    flex: none;
    position: relative;
    &-link {
      color: $light-color;
      text-decoration: none;
    }
    &-img {
      opacity: .6;
      transition: all 0.35s ease-in-out;
      transform: scale(1);
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 10px 20px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      transition: all 0.35s ease-in-out;
      width: 100%;
      z-index: 1;
    }
    &-ttl {
      color: $light-color;
      font-size: 22px;
      text-transform: uppercase;
    }
    &-btn {
      transition: all .5s ease;
      margin-top: 0;
      height: 0;
      opacity: 0;
      position: relative;
      text-align: center;
      z-index: 1;
    }
    &:hover {
      .works-slider__item-content {
        background: rgba($secondary-color, 0.3);
      }
      .works-slider__item-btn {
        height: 38px;
        margin-top: 10px;
        opacity: 1;
      }
    }
  }

  .slick-next, .slick-prev {
    background-color: rgba($light-color, .8);
    height: 80px;
    margin-top: -40px;
    opacity: 0;
    width: 40px;
    &:before {
      margin: -9px -5px;
      left: 50%;
      top: 50%;
      right: auto;
    }
    &:hover {
      background-color: $light-color;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }

  @include laptop {
    &__item {
      &-ttl {
        font-size: 20px;
      }
    }
  }
  @include tablets {
    &__item {
      &-img {
        img {
          //height: 180px;
        }
      }
      &-ttl {
        font-size: 17px;
      }
    }
  }
}