.uppercase { text-transform: uppercase; }
.clear {clear:both;}
.left {float: left;}
.right  {float: right;}
.fz19 {
  font-size: 1.1875em;
  @include tablets {
    font-size: 15px;
  }
}
.fz20 {
  font-size: 1.25em;
  @include tablets {
    font-size: 15px;
  }
}
.img-left {
  float: left;
  margin:0 20px 15px 0;
  max-width: 50%;
  @include phones {
    display: block;
    float: none;
    margin: 0 auto 15px;
    max-width: 100%;
  }
}
.img-right  {
  float: right;
  margin:0 0 15px 20px;
  max-width: 50%;
  @include phones {
    display: block;
    float: none;
    margin: 0 auto 15px;
    max-width: 100%;
  }
}
.img-border {border-radius: $border-radius-base}
.t-left {text-align: left;}
.t-right  {text-align: right;}
.center {text-align: center;}
.ofl {overflow: hidden;	_zoom: 1;}
.red {color: $attention-color;}
.orange {color: $primary-color;}
.bold {font-weight: 600;}
.bbold {font-weight: 600; font-size: 16px;}
.hidden {display: none;}
.nowrap {white-space: nowrap;}
.small {font-size: 14px;}

.clearfix {
  @extend %clearfix;
}

.ttl-base {
  @extend %ttl-base;
  &_main {
    text-transform: none;
  }
}

.ttl-base-s {
  @extend %ttl-base-s;
}

.dotted-link {
  border-bottom: 1px dotted;
  text-decoration: none;
  &:hover {
    border-color: transparent;
  }
}

.content .ol-list-block {margin-left: 0;}

.ol-list-block {
    counter-reset: ol-list;
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    & > li {
		list-style: none;
        background: #f1f1f1;
        counter-increment: ol-list;
        margin-left: 2%;
        margin-top: 35px;
        margin-bottom: 20px;
        padding: 50px 30px 30px;
        position: relative;
        width: 49%;
        &:first-child {margin-left: 0;}
        &:before {
            background: #2c6099;
            border-radius: 50%;
            color: #fff;
            content: counter(ol-list);
            display: block;
            font-weight: 700;
            height: 70px;
            left: 50%;
            line-height: 70px;
            margin-left: -35px;
            position: absolute;
            text-align: center;
            top: -35px;
            width: 70px;
        }
    }
}

@media all and (max-width: 768px) {
    .ol-list-block > li {
        margin-left: 0;
        width: 100%;
    }
}