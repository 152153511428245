.btn {
  @extend %btn;
  &_base {
    @include btn-theme($light-color, $base-color);
  }
  &_primary {
    @include btn-theme($light-color, $primary-color);
  }
  &_secondary {
    @include btn-theme($light-color, $secondary-color);
  }
  &_s {
    font-size: 14px;
    font-weight: normal;
    padding: 12px 25px;
  }
}