.main-slider {
  padding: 0;
  &__slidee {
    height: 430px;
    overflow: hidden;
  }
  .slick-prev,
  .slick-next {
    margin-top: -80px;
  }
  &__item {
    background-position: 50% 50%;
    background-size: cover;
    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 430px;
      padding: 60px 0 100px;
    }
    &-desc {
      color: $light-color;
      font-size: 42px;
      opacity: .6;
      text-align: center;
      text-transform: uppercase;
    }
    &-ttl {
      font-size: 1.35em;
      font-weight: bold;
    }
    &-text {
      margin-top: 15px;
    }
    &-btn {
      margin-top: 30px;
      text-align: center;
      .btn {
        width: 240px;
      }
    }
  }
  @include _desktop {
    .slick-prev {
      margin-top: -80px;
      left: 50%;
      transform: translateX(-580px);
    }
    .slick-next {
      right: 50%;
      transform: translateX(580px);
    }
  }

  .slick-dots {
    display: none;
  }

  @include laptop {
    &__item {
      &-content {
        padding-top: 40px;
      }
      &-desc {
        font-size: 28px;
      }
      &-btn {
        .btn {
          width: auto;
        }
      }
    }
  }
  @include _tablets {
    .slick-dots {
      display: none !important;
    }
  }
  @include tablets {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-dots {
      display: block;
    }
    &__slidee {
      height: 350px;
    }
    &__item {
      &-content {
        height: 350px;
        padding: 20px 0 30px;
      }
      &-desc {
        font-size: 21px;
      }
    }
  }
  @include phones {
    &__slidee {
      height: 220px;
    }
    &__item {
      &-content {
        height: 220px;
      }
      &-btn {
        margin-top: 20px;
      }
      &-desc {
        font-size: 19px;
      }
    }
  }
}