html {
  color: $text-color;
  font-size: $font-size-base;
  font-family: $font-family-base;
  @include laptop {
    font-size: 15px;
  }
}
body#tinymce {
  color: $text-color;
  font-size: $font-size-base;
  font-family: $font-family-base;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  transition: all .2s ease;
  &:hover, &:active {
    text-decoration: $link-hover-decoration;
  }
  .svg-icon {
    transition: fill .2s ease;
  }
}

table {
  border-collapse: collapse;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  margin-bottom: 1em;
  font-size: 40px;
  font-weight: bold;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;  
}

h2 {
  @extend %ttl-base;
  margin-top: 0;
}

h3 {
  @extend %ttl-base-s;
  margin-top: 0;
}

.content {
  p {
    line-height: 1.5;
  }
  p, ul, ol {
    margin-bottom: 1.2em;
    margin-top: 0;
  }
  ul {
    list-style: none;
    li {
      margin: 5px 0;
      padding-left: 20px;
      position: relative;
      &:before {
        background-color: $primary-color;
        border-radius: 7px;
        content: '';
        height: 7px;
        position: absolute;
        top: 6px;
        left: 0;
        width: 7px;
      }
    }
  }
  ol {
    margin-left: 20px;
    list-style-position: outside;
    list-style-type: decimal;
  }
  table {
    font-size: 15px;
    &:not(.price-tbl) {
      background-color: $bg-color;
      border-bottom: 2px solid rgba($light-color, .5);
      margin-bottom: 1.4em;
      td,th {
        &:first-child {
          padding-left: 16px;
        }
      }
      th {
        background-color: $primary-color;
        border: none;
        color: $light-color;
        font-weight: normal;
        height: 50px;
        text-align: left;
      }
      tr {
        &:nth-child(2n) {
          background-color: rgba($light-color, .5);
        }
      }
    }
    width: 100%;
    @include phones {
      font-size: 13px;
    }
  }
  td,th {
    padding: 10px 8px;
  }

}
