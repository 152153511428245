.popup {
  display: none;
}

#MyCMSDialogShadow {
  background-color: rgba(#000, 0.8);
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.mycms-dialog {
  animation-duration: .3s;
  padding: 0 10px 10px;
  position: absolute;
  text-align: center;
  min-width: 320px;
  width: 100%;
  iframe {
    border: none;
  }
  &__container {
    background-color: $light-color;
    border-radius: $border-radius-base;
    display: inline-block;
    vertical-align: top;
    padding: 40px;
    position: relative;
    margin: 0 auto;
    text-align: left;
    max-width: 100%;
    width: 700px;
  }
  &__title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 1.2em;
    text-align: center;
  }
  &__close {
    @include close-btn($base-color, 3px, 40px, transparent);
    position: absolute;
    right: 0;
    top: 0;
  }
  &__content {

  }
  @include laptop {
    &__container {
      padding: 20px;
    }
  }
  @include phones {
    &__container {
      padding: 15px;
    }
    &__title {
      font-size: 18px;
      margin-bottom: .6em;
    }
  }
}

.ok-message {
  font-size: 18px;
  padding: 20px 0;
  text-align: center;
}