.service-menu {
  margin-top: 10px;
  position: relative;
  z-index: 1;
  &__list {
    display: flex;
    margin-left: -24px;
  }
  &__item {
    flex: 1;
    margin-left: 24px;
    position: relative;
    @include _tablets {
      &:hover {
        .service-menu__sub {
          transition: opacity .3s ease .1s;
          left: -5px;
          right: -5px;
          opacity: 1;
        }
        .service-menu__ttl {
          box-shadow: 0 -5px 0 5px $secondary-color;
        }
      }
    }
    &:hover {
      .service-menu__ttl {
        background-color: $secondary-color;
        .service-menu__ttl-icon {
          opacity: 1;
        }
        .service-menu__ttl-name {
          opacity: 1;
        }
      }
      .service-menu__tggl {
        color: $light-color;
      }
    }
  }
  &__ttl-wrap {
    height: 100%;
    position: relative;
  }
  &__tggl {
    color: $secondary-color;
    cursor: pointer;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    &-arrow {
      margin-top: -9px;
      position: absolute;
      right: 10px;
      top: 50%;
      transition: all .2s ease;
    }
    &.is-open {
      .service-menu__tggl-arrow {
        transform: rotate(90deg);
      }
    }
    @include _tablets {
      display: none;
    }
  }
  &__ttl {
    background-color: $base-color;
    border-bottom: 8px solid $secondary-color;
    color: $light-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 15px 28px;
    text-align: center;
    text-decoration: none;
    &-icon {
      @extend %centered-content;
      height: 70px;
      margin-bottom: 5px;
      opacity: .6;
    }
    &-name {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.1;
      opacity: .8;
      text-transform: uppercase;
    }
    &.active {
      background-color: $secondary-color;
      .service-menu__ttl-icon {
        opacity: 1;
      }
      .service-menu__ttl-name {
        opacity: 1;
      }
      & + .service-menu__tggl {
        color: $light-color;
      }
    }
  }
  &__sub {
    background-color: $base-color;
    display: none;
    @include _tablets {
      display: block !important;
      opacity: 0;
      position: absolute;
      left: -9999px;
    }
    &-item {
      border-bottom: 1px solid rgba($light-color, .2);
    }
    &-link {
      color: $light-color;
      display: block;
      font-size: 16px;
      padding: 10px 25px;
      text-decoration: none;
      &:hover {
        background-color: rgba($light-color, .08);
      }
    }
  }
  @include desktop {
    &__list {
      margin-left: -15px;
    }
    &__item {
      margin-left: 15px;
    }
    &__ttl {
      padding: 10px 15px;
      &-name {
        font-size: 16px;
      }
    }
    &__sub {
      &-link {
        padding: 8px 10px;
      }
    }
  }
  @include _tablets {
    &_index {
      margin-top: -80px;
    }
    &_inner {
      background: url("/skin/images/services_bg.jpg") 50% 50% no-repeat;
      background-size: cover;
      margin-top: 0;
      .service-menu__list {
        margin-bottom: -8px;
      }
    }
  }
  @include tablets {
    &__list {
      flex-direction: column;
    }
    &__item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__ttl {
      border-width: 4px;
      flex-direction: row;
      padding: 10px 25px 10px 10px;
      text-align: left;
      &.active {
        border-bottom: 0;
      }
      &-icon {
        flex: none;
        height: 40px;
        margin: 0 10px 0 0;
        width: 40px;
        img {
          max-height: 100%;
        }
      }
      &-name {
        display: block;
      }
    }
  }
}