.breadcrumbs {
  margin-bottom: 30px;
  &__item {
    color: $text-color-light;
    &:not(:last-child) {
      &:after {
        content: '>';
        margin: 0 5px;
      }
    }
  }
  @include tablets {
    margin-bottom: 20px;
  }
}