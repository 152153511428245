.main-header {
  position: relative;
  z-index: 2;
  &__top {
    background-color: $base-color;
    color: $light-color;
    a {
      color: $light-color;
    }
    &-layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      @include phones {
        flex-direction: column;
      }
    }
  }
  &__nav {
    background-color: $primary-color;
    color: $light-color;
    &-layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  @include desktop {
    &__nav {
      .layout-main {
        padding-right: 0;
      }
    }
  }
  @include tablets {
    &__nav {
      .layout-main {
        padding-left: 0;
      }
    }
  }
}

.header-links {
	font-size: 12px;
	opacity: .7;
	margin: auto 10px;
	text-align: center;
	span{
		white-space: nowrap;
		line-height: 30px;
	}
}

.header-address {
  font-size: 15px;
  margin-right: 20px;
  opacity: .7;
  @include phones {
    margin: 0 0 10px;
    text-align: center;
  }
}

.header-phones {
  display: flex;
  align-items: center;
  flex: none;
  &__icon {
    color: $primary-color-light;
    margin-right: 15px;
    .icon-phone {
      font-size: 23px;
    }
  }
  &__phones {
    font-size: 21px;
    font-weight: 500;
    opacity: .8;
    &-item {
      &:not(:last-child) {
        &:after {
          content: ';';
          margin: 0 5px 0 -4px;
        }
      }
    }
    &-link {
      color: $light-color;
      text-decoration: none;
    }
  }
  @include laptop {
    &__phones {
      font-size: 18px;
    }
  }
  @include tablets {
    &__phones {
      &-item {
        display: block;
        &:after {
          display: none;
        }
      }
    }
  }
}

.header-logo {
  flex: none;
  margin-right: 15px;
  &__link {
    display: block;
    text-decoration: none;
  }
  &__img {

  }
  &__text {
    color: $primary-color-lightest;
    font-size: 17px;
  }
  @include laptop {
    text-align: center;
    &__img {
      img {
        width: 210px;
      }
    }
    &__text {
      font-size: 13px;
    }
  }
  @include tablets {
    flex: 1;
    margin: 0 5px;
  }
}

.header-nav {
  border-left: 1px solid rgba($primary-color-lighter, .5);
  display: flex;
  align-items: center;
  &__menu {

  }
  @include tablets {
    padding: 20px;
    &__menu {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
    }
  }
  @include _tablets {
    &__menu {
      display: block !important;
    }
  }
}

.main-menu {
  display: flex;
  &__item {
      border-right: 1px solid rgba($primary-color-lighter, .5);
  }
  &__link {
    color: $light-color;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    height: 100px;
    padding: 0 16px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover,
	&.active{
      background-color: $primary-color-dark;
    }
  }
  @include desktop {
    &__item {
      &:last-child {
        border-right: none;
      }
    }
  }
  @include laptop {
    &__link {
      font-size: 14px;
      height: 90px;
      padding: 0 10px;
    }
  }
  @include tablets {
    border-top: 1px solid $light-color;
    background-color: $primary-color;
    flex-direction: column;
    &__item {
      border-right: none;
      border-bottom: 1px solid rgba($primary-color-lighter, .5);
    }
    &__link {
      display: block;
      font-size: 20px;
      height: auto;
      padding: 8px 15px;
      text-align: center;
    }
  }
}

.menu-btn {
  display: none;
  height: 40px;
  position: relative;
  z-index: 3;
  width: 40px;
  &__icon {
    background: $light-color;
    height: 4px;
    position: absolute;
    top: 18px;
    left: 7px;
    right: 7px;
    transition: background 0 .3s;
    z-index: 1;
    &:before,
    &:after {
      background-color: $light-color;
      content: '';
      height: 4px;
      position: absolute;
      left: 0;
      width: 100%;
      transition-duration: .3s, .3s;
    }
    &:before {
      top: -10px;
      transition-property: top, transform;
    }
    &:after {
      bottom: -10px;
      transition-property: bottom, transform;
    }
  }
  &.is-open {
    .menu-btn__icon {
      background: none;
      &:before {
        top: 0;
        transform: rotate(45deg);
        transition-delay: 0, .3s;
      }
      &:after {
        bottom: 0;
        transform: rotate(-45deg);
        transition-delay: 0, .3s;
      }
    }
  }
  @include tablets {
    display: block;
  }
}